import React, { useState, useEffect } from "react";

export default function AlphaDetails() {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch the details from the backend
  const fetchDetails = async () => {
    try {
      const response = await fetch("https://mindlr.ai/api/get-details", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDetails(data);
      } else {
        throw new Error("Failed to fetch details");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      setError("Failed to fetch details. Please try again later.");
    } finally {
      setLoading(false); // Ensure loading is stopped
    }
  };

  // Fetch details on component mount
  useEffect(() => {
    fetchDetails();
  }, []);

  // Show loading indicator while fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle errors during fetching
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-6">AlphaDetails List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {details.map((detail) => (
          <div
            key={detail._id}
            className="bg-white p-6 rounded-lg shadow-md w-80 text-center"
          >
            <h2 className="text-black text-2xl font-semibold mb-2">
              {detail.name}
            </h2>
            <p className="text-black">Email: {detail.email}</p>
            <p className="text-black">Instagram ID: {detail.instaId}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
