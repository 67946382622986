import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VideoUploadPopup = ({ onClose, onUploadComplete }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [tagLine, setTagLine] = useState("");
  const [aiTool, setAiTool] = useState("");
  const [description, setDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [uploading, setUploading] = useState(false);

  const showErrorToast = (message) => {
    toast.error(message, {
      autoClose: 2000, // Close after 2 seconds
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "errorToast",
    });
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      autoClose: 2000,
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "successToast",
    });
  };

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile) {
      showErrorToast("Please select a video.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("tagLine", tagLine);
    formData.append("aiTool", aiTool);
    formData.append("description", description);
    formData.append("caption", caption);

    try {
      const response = await fetch("https://mindlr.ai/api/uploadVideo", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (response.ok) {
        showSuccessToast("Video uploaded!");

        // Delay closing the popup to allow the toast to show
        setTimeout(() => {
          onUploadComplete();
          onClose();
        }, 2000); // Close after 2 seconds, matching the toast duration
      } else {
        showErrorToast("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      showErrorToast("An error occurred while uploading the video.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed p-10 md:p-0 mt-3 mb-28 md:mb-0 md:mt-0 inset-0 bg-black bg-opacity-75 flex justify-center items-center backdrop-blur-sm z-50">
      <div className="bg-slate-500 p-8 rounded-lg w-full max-w-3xl">
        <h2 className="text-3xl font-poppins mb-6 text-white text-center">
          Upload Video
        </h2>

        <div className="mb-4">
          <label className="block text-white font-poppins mb-2">
            Select the video
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            className="w-full text-gray-700 p-2 rounded-md border border-gray-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-white font-poppins mb-2">Tag Line</label>
          <input
            type="text"
            value={tagLine}
            onChange={(e) => setTagLine(e.target.value)}
            className="w-full p-2 rounded-md border border-gray-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-white font-poppins mb-2">AI Tool</label>
          <input
            type="text"
            value={aiTool}
            onChange={(e) => setAiTool(e.target.value)}
            className="w-full p-2 rounded-md border border-gray-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-white font-poppins mb-2">
            Description of the Tool
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 rounded-md border border-gray-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-white font-poppins mb-2">Caption</label>
          <textarea
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            className="w-full p-2 rounded-md border border-gray-300"
          />
        </div>

        <div className="flex justify-end mt-4 md:mt-6 pb-4 md:pb-0">
          <button
            onClick={onClose}
            className="bg-red-600 text-white px-6 py-2 rounded-full mr-4"
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            className={`bg-green-600 text-white px-6 py-2 rounded-full ${
              uploading ? "opacity-50" : ""
            }`}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Post"}
          </button>
        </div>

        {/* Add the ToastContainer here to render the toasts */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default VideoUploadPopup;
