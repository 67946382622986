import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckOutForm";
import { useNavigate } from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function ToolMakerWelcome() {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(""); // Track user-entered amount
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeStripe = async () => {
      await stripePromise;
      console.log("Stripe.js has been successfully loaded.");
      setStripeLoaded(true);
    };

    initializeStripe();
  }, []);

  const handlePayment = async () => {
    if (!paymentAmount || isNaN(paymentAmount) || paymentAmount <= 0) {
      alert("Please enter a valid payment amount.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        "https://mindlr.ai/api/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: paymentAmount }),
        }
      );

      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error creating payment intent:", error);
      alert("Error initiating payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessfulPayment = async () => {
    try {
      const creditsToAdd = paymentAmount * 5; // Calculate credits (1 USD = 5 credits)
      const response = await fetch("https://mindlr.ai/api/add-credits", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include user session
        body: JSON.stringify({ creditsToAdd }),
      });

      if (response.ok) {
        const updatedData = await response.json();
        alert(
          `Payment successful! Your new credit balance is ${updatedData.credits} credits.`
        );
        navigate("/home");
      } else {
        alert("Error updating credits. Please contact support.");
      }
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 py-10 px-4">
      <h2 className="text-4xl font-poppins text-white mb-10">
        Purchase Credits
      </h2>
      <div className="bg-slate-700 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h3 className="text-2xl text-white font-poppins mb-6 text-center">
          Payment Details
        </h3>
        <p className="text-white text-center mb-4">1 USD = 5 Credits</p>
        <input
          type="number"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          placeholder="Enter amount in USD"
          className="w-full p-3 rounded-md border border-gray-300 mb-6"
        />
        <button
          onClick={handlePayment}
          disabled={loading}
          className="bg-green-600 font-poppins text-white px-6 py-3 rounded-full w-full"
        >
          {loading ? "Processing..." : "Proceed to Pay"}
        </button>
        {clientSecret && stripeLoaded && (
          <div className="mt-6">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                onSuccess={handleSuccessfulPayment} // Pass callback for successful payment
              />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
}
