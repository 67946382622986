import React from "react";

export default function ToolBuyPopup({ onClose, onNavigate }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-96">
        <h2 className="text-xl font-bold text-gray-800 text-center mb-4">
          Purchase More Credits
        </h2>
        <p className="text-gray-600 text-center mb-6">
          You don't have enough credits to customize further. Purchase more
          credits to continue customizing your blueprints!
        </p>
        <button
          onClick={onNavigate}
          className="w-full py-2 bg-blue-500 text-white rounded-md text-lg hover:bg-blue-400 focus:outline-none mb-4"
        >
          Buy Credits Now
        </button>
        <button
          onClick={onClose}
          className="w-full py-2 bg-gray-300 text-gray-800 rounded-md text-lg hover:bg-gray-200 focus:outline-none"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
