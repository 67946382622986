import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const CheckoutForm = ({ clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet, please try again later.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Card details not entered.");
      setProcessing(false);
      return;
    }

    try {
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          setSuccess(true);
          if (onSuccess) {
            onSuccess(); // Trigger the callback function after successful payment
          }
        }
      }
    } catch (error) {
      setError("An error occurred during payment.");
    } finally {
      setProcessing(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "18px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto p-4">
      <div className="mb-4">
        <label className="block text-white text-lg mb-2">Card Details</label>
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-100 shadow-lg">
          <CardElement options={cardElementOptions} />
        </div>
      </div>
      <button
        type="submit"
        disabled={!stripe || processing}
        className="bg-green-500 hover:bg-green-500 text-white font-poppins font-bold py-2 px-4 rounded-full w-full mt-4"
      >
        {processing ? "Processing..." : "Pay"}
      </button>
      {error && <div className="text-red-500 mt-4">{error}</div>}
      {success && (
        <div className="text-green-500 mt-4">Payment successful!</div>
      )}
    </form>
  );
};

export default CheckoutForm;
