import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Layout from "./components/Layout";
import SimpleLayout from "./components/SimpleLayout";
import ContactForm from "./components/ContactForm";
import WelcomePage from "./pages/WelcomePage";
import Blog from "./pages/Blog";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import UploaderProfile from "./pages/UploaderProfile";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute component
import AlphaDetailsForm from "./components/AlphaDetailsForm";
import AlphaDetails from "./pages/AlphaDetails";
import AlphaInputs from "./pages/AlphaInputs";
import AlphaRelevance from "./pages/AlphaRelevance";
import ToolMakerWelcome from "./pages/ToolMakerWelcome";
import WelcomePage2 from "./pages/WelcomePage2";
import LandingPageVersion2 from "./pages/LandingPageVersion2";
import DemoCanvas from "./pages/DemoCanvas";
import People from "./pages/People";
import QueriesandBlueprints from "./pages/QueriesandBlueprints";
import TimeStats from "./pages/TimeStats";

function App() {
  const [user, setUser] = useState(null);
  const location = useLocation(); // Get the current route

  // Fetch user info when the app loads
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://mindlr.ai/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Global time tracking across all pages
  useEffect(() => {
    if (!user) return; // Wait until user info is loaded

    let sessionStart = Date.now(); // Start time of the session
    let lastActive = sessionStart; // Last time the user was active
    let activeTime = 0; // Total active time

    const handleVisibilityChange = () => {
      const now = Date.now();
      if (document.visibilityState === "hidden") {
        // User switched away from the tab
        activeTime += now - lastActive;
      } else {
        // User returned to the tab
        lastActive = now;
      }
    };

    const logSessionTime = async () => {
      const sessionEnd = Date.now();
      if (document.visibilityState === "visible") {
        activeTime += sessionEnd - lastActive;
      }
      const sessionDuration = Math.max(1, Math.floor(activeTime / 1000)); // Convert to seconds

      console.log("Logging session data:", {
        email: user.email,
        sessionStart,
        sessionEnd,
        sessionDuration,
      });

      try {
        await fetch("https://mindlr.ai/api/log-session-time", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            sessionStart,
            sessionEnd,
            sessionDuration,
          }),
        });
        console.log("Session logged successfully.");
      } catch (error) {
        console.error("Failed to log session time:", error);
      }
    };

    // Event listeners for visibility change and before unload
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", logSessionTime);

    // Cleanup on component unmount
    return () => {
      logSessionTime();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", logSessionTime);
    };
  }, [user]);

  return (
    <Routes>
      {/* Routes using the Standard Layout with Header and Footer */}
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/sign-in"
        element={
          <Layout>
            <SignIn />
          </Layout>
        }
      />
      <Route
        path="/register"
        element={
          <Layout>
            <Register />
          </Layout>
        }
      />
      <Route
        path="/about"
        element={
          <Layout>
            <About />
          </Layout>
        }
      />
      <Route
        path="/join-beta"
        element={
          <Layout>
            <ContactForm />
          </Layout>
        }
      />
      <Route
        path="/blog"
        element={
          <Layout>
            <Blog />
          </Layout>
        }
      />
      <Route
        path="/blog1"
        element={
          <Layout>
            <Blog1 />
          </Layout>
        }
      />
      <Route
        path="/blog2"
        element={
          <Layout>
            <Blog2 />
          </Layout>
        }
      />
      <Route
        path="/landingpage-version2"
        element={
          <Layout>
            <LandingPageVersion2 />
          </Layout>
        }
      />

      {/* Routes using Simple Layout (without Header and Footer) */}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <SimpleLayout>
              <WelcomePage />
            </SimpleLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <SimpleLayout>
              <Profile />
            </SimpleLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/uploader-profile/:uploaderId"
        element={
          <PrivateRoute>
            <SimpleLayout>
              <UploaderProfile />
            </SimpleLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/welcome"
        element={
          <SimpleLayout>
            <AlphaDetailsForm />
          </SimpleLayout>
        }
      />
      <Route
        path="/alpha-form-details"
        element={
          <SimpleLayout>
            <AlphaDetails />
          </SimpleLayout>
        }
      />
      <Route
        path="/alpha-user-feedback"
        element={
          <SimpleLayout>
            <AlphaInputs />
          </SimpleLayout>
        }
      />
      <Route
        path="/alpha-video-relevantornot"
        element={
          <SimpleLayout>
            <AlphaRelevance />
          </SimpleLayout>
        }
      />
      <Route
        path="/toolmaker-welcome"
        element={
          <SimpleLayout>
            <ToolMakerWelcome />
          </SimpleLayout>
        }
      />
      <Route
        path="/home-version2"
        element={
          <SimpleLayout>
            <WelcomePage2 />
          </SimpleLayout>
        }
      />
      <Route
        path="/democanvas"
        element={
          <SimpleLayout>
            <DemoCanvas />
          </SimpleLayout>
        }
      />
      <Route
        path="/home/people"
        element={
          <SimpleLayout>
            <People />
          </SimpleLayout>
        }
      />
      <Route
        path="/queriesandblueprints"
        element={
          <SimpleLayout>
            <QueriesandBlueprints />
          </SimpleLayout>
        }
      />
      <Route
        path="/timestats"
        element={
          <SimpleLayout>
            <TimeStats />
          </SimpleLayout>
        }
      />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
