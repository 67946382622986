import React, { useState } from "react";

export default function DemoCanvas() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [image, setImage] = useState(null); // For Step 1: Generated image
  const [uploadedFile, setUploadedFile] = useState(null); // For Step 2: Uploaded file
  const [video, setVideo] = useState(null); // For Step 2: Generated video
  const [lipSyncVideo, setLipSyncVideo] = useState(null); // For Lip Sync result
  const [prompt, setPrompt] = useState(""); // Prompt shared across steps
  const [uploadedFace, setUploadedFace] = useState(null); // For Lip Sync: Face input
  const [uploadedAudio, setUploadedAudio] = useState(null); // For Lip Sync: Audio input

  const log = (message, data) => {
    console.log(`[DEBUG] ${message}`, data || "");
  };

  const generateImage = async () => {
    if (!prompt.trim()) {
      console.error("[ERROR] Prompt is required for Step 1");
      return;
    }

    try {
      setLoading(true);
      setProgress(0);
      setCompleted(false);

      log("Initiating image generation API call with prompt", prompt);

      const response = await fetch(
        "https://mindlr.ai/api/images/generate-image",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt }),
        }
      );

      const data = await response.json();
      if (response.ok && data.imageUrl) {
        setImage(data.imageUrl);
        setCompleted(true);
        setProgress(100);
        log("Image generated successfully", data.imageUrl);
      } else {
        console.error("[ERROR] Unexpected API response:", data);
      }
    } catch (error) {
      console.error("[ERROR] Error occurred during image generation:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateVideo = async () => {
    if (!uploadedFile || !prompt.trim()) {
      console.error("[ERROR] Both image and prompt are required for Step 2");
      return;
    }

    const formData = new FormData();
    formData.append("image", uploadedFile); // Key must match backend
    formData.append("prompt", prompt);

    try {
      setLoading(true);
      setProgress(0);
      setCompleted(false);

      const response = await fetch(
        "https://mindlr.ai/api/videos/generate-video",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const blob = await response.blob(); // Convert ReadableStream to Blob
        const videoUrl = URL.createObjectURL(blob); // Create URL for the blob
        setVideo(videoUrl);
        setCompleted(true);
        setProgress(100);
        log("[SUCCESS] Video generation successful", videoUrl);
      } else {
        const errorData = await response.json();
        console.error("[ERROR] Backend error response:", errorData);
      }
    } catch (error) {
      console.error("[ERROR] Error during video generation:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateLipSync = async () => {
    if (!uploadedFace || !uploadedAudio) {
      console.error("[ERROR] Both face and audio files are required.");
      return;
    }

    const formData = new FormData();
    formData.append("face", uploadedFace); // Key must match backend
    formData.append("audio", uploadedAudio);

    try {
      setLoading(true);
      setProgress(0);
      setCompleted(false);

      const response = await fetch(
        "https://mindlr.ai/api/videos/generate-lipsync",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const blob = await response.blob(); // Convert ReadableStream to Blob
        const lipSyncVideoUrl = URL.createObjectURL(blob); // Create URL for the blob
        setLipSyncVideo(lipSyncVideoUrl);
        setCompleted(true);
        setProgress(100);
        log("[SUCCESS] Lip Sync generation successful", lipSyncVideoUrl);
      } else {
        const errorData = await response.json();
        console.error("[ERROR] Backend error response:", errorData);
      }
    } catch (error) {
      console.error("[ERROR] Error during Lip Sync generation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    log("File selected:", file.name);
  };

  const handleFaceChange = (event) => {
    const file = event.target.files[0];
    setUploadedFace(file);
    log("Face file selected:", file.name);
  };

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    setUploadedAudio(file);
    log("Audio file selected:", file.name);
  };

  const handleAction = async () => {
    if (currentStep === 1) {
      await generateImage();
    } else if (currentStep === 2) {
      await generateVideo();
    } else if (currentStep === 3) {
      await generateLipSync();
    }
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 1:
        return {
          heading: "Text to Image using Tool A",
          buttonText: "Create Image",
          content: (
            <input
              type="text"
              placeholder="Enter a prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="block w-full text-sm text-gray-500 p-2 rounded-lg bg-gray-700 border border-gray-600 mb-4"
            />
          ),
        };
      case 2:
        return {
          heading: "Image to Video using Tool B",
          buttonText: "Create Video",
          content: (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="block mb-4 text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-green-500 file:text-black hover:file:bg-green-400"
              />
              <input
                type="text"
                placeholder="Describe the image"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="block w-full text-sm text-gray-500 p-2 rounded-lg bg-gray-700 border border-gray-600 mb-4"
              />
            </>
          ),
        };
      case 3:
        return {
          heading: "Add Lip Sync using Tool C",
          buttonText: "Generate Lip Sync",
          content: (
            <>
              <input
                type="file"
                accept="image/*,video/*"
                onChange={handleFaceChange}
                className="block mb-4 text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-green-500 file:text-black hover:file:bg-green-400"
              />
              <input
                type="file"
                accept="audio/*,video/*"
                onChange={handleAudioChange}
                className="block mb-4 text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-green-500 file:text-black hover:file:bg-green-400"
              />
            </>
          ),
        };
      default:
        return {
          heading: "",
          buttonText: "Run",
          content: null,
        };
    }
  };

  const { heading, buttonText, content } = getStepContent();

  return (
    <div className="bg-gray-300 min-h-screen flex flex-col py-8 px-8 font-sans relative">
      <h2 className="text-5xl font-extrabold text-black bg-green-500 px-6 py-3 w-fit rounded shadow mb-6 tracking-wide mx-auto">
        Demo Canvas
      </h2>

      <div className="flex flex-col space-y-4 w-fit mx-auto z-10">
        {[1, 2, 3].map((step) => (
          <div
            key={step}
            onClick={() => {
              setCurrentStep(step);
              setImage(null);
              setUploadedFile(null);
              setVideo(null);
              setLipSyncVideo(null);
              log(`Switched to Step ${step}`);
            }}
            className={`px-6 py-3 rounded-lg border-2 text-center cursor-pointer ${
              currentStep === step
                ? "border-green-500 bg-gray-800"
                : "border-gray-500 bg-gray-700"
            } text-gray-200 text-lg font-bold`}
          >
            {`Step ${step}: ${
              step === 1
                ? "Text to Image"
                : step === 2
                ? "Image to Video"
                : "Add Lip Sync"
            }`}
          </div>
        ))}
      </div>

      <div className="absolute top-32 right-8 w-1/4 bg-gray-900 rounded-lg shadow-lg p-6 border-2 border-green-500 flex flex-col items-center justify-center">
        <h3 className="text-2xl font-bold text-white mb-4 text-center">
          {heading}
        </h3>
        {content}
        <button
          onClick={handleAction}
          className={`bg-green-500 text-black font-bold px-6 py-2 rounded-lg hover:bg-green-400 transition duration-300 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading ? `Processing (${progress}%)...` : buttonText}
        </button>

        {loading && (
          <div className="mt-4 w-full flex flex-col items-center">
            <div className="w-10 h-10 border-4 border-green-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="text-green-500 mt-2">{progress}%</p>
          </div>
        )}

        {image && (
          <div className="mt-4">
            <h3 className="text-lg text-white mb-2">Generated Image</h3>
            <img
              src={image}
              alt="Generated"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        )}

        {video && (
          <div className="mt-4">
            <h3 className="text-lg text-white mb-2">Generated Video</h3>
            <div className="w-full h-auto rounded-lg shadow-lg p-4 bg-gray-800">
              <video controls className="w-full h-auto rounded-lg">
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        )}

        {lipSyncVideo && (
          <div className="mt-4">
            <h3 className="text-lg text-white mb-2">Lip Sync Video</h3>
            <div className="w-full h-auto rounded-lg shadow-lg p-4 bg-gray-800">
              <video controls className="w-full h-auto rounded-lg">
                <source src={lipSyncVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
