import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function SignIn() {
  const [identifier, setIdentifier] = useState(""); // Identifier can be email or username
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { identifier, password };

    try {
      const response = await fetch("https://mindlr.ai/api/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.message === "Sign in successful") {
          navigate("/home");
        }
      } else if (response.status === 400) {
        setErrorMessage("Invalid credentials. Please try again.");
      } else if (response.status === 404) {
        setErrorMessage("User does not exist. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error signing in", error);
      setErrorMessage(
        "An error occurred while signing in. Please try again later."
      );
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await fetch(
        "https://mindlr.ai/api/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.status === 200) {
        toast.success("Password reset email sent successfully.");
        setForgotPasswordPopup(false);
      } else if (response.status === 404) {
        toast.error("User not found. Please check your email.");
      } else {
        toast.error("Error resetting password. Please try again.");
      }
    } catch (error) {
      console.error("Error in forgot-password:", error);
      toast.error("Server error. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center mt-12 mb-12 px-4 relative">
      <NeonGradientCard className="w-full max-w-md p-0 bg-black">
        <form onSubmit={handleSubmit} className="px-6 py-8">
          <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
            Sign In
          </h2>
          <div className="mb-4">
            <label
              htmlFor="identifier"
              className="block mb-2 font-poppins text-black dark:text-white"
            >
              Email/Username
            </label>
            <input
              type="text"
              id="identifier"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 font-poppins text-black dark:text-white"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
              <span
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </span>
            </div>
          </div>

          {errorMessage && (
            <p className="text-red-500 font-poppins mb-4 text-center">
              {errorMessage}
            </p>
          )}

          <div className="flex flex-col items-center mt-4">
            <ShimmerButton className="font-poppins mx-auto md:mx-0">
              Sign In
            </ShimmerButton>
            <button
              type="button"
              className="mt-4 text-blue-500 hover:underline"
              onClick={() => setForgotPasswordPopup(true)}
            >
              Forgot Password?
            </button>
          </div>
        </form>
      </NeonGradientCard>

      {forgotPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50">
          <div className="bg-gray-200 p-8 rounded-lg shadow-xl w-96 relative z-50">
            <h3 className="text-2xl font-semibold font-poppins mb-6 text-center text-gray-800">
              Reset Password
            </h3>
            <label
              htmlFor="email"
              className="block mb-2 ml-2 text-gray-800 font-semibold font-poppins"
            >
              Enter your email
            </label>
            <input
              id="email"
              type="email"
              placeholder="example@domain.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <div className="flex justify-start gap-4">
              <button
                onClick={() => setForgotPasswordPopup(false)}
                className="px-6 py-2 bg-gray-600 text-white font-poppins rounded-lg hover:bg-gray-700 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleForgotPassword}
                className="px-6 py-2 bg-blue-500 text-white font-poppins rounded-lg hover:bg-blue-600 transition-all"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
