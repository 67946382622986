import React, { useState } from "react";

const FeedbackForm = ({ userId }) => {
  const [showPopup, setShowPopup] = useState(false); // Toggle for popup
  const [feedback, setFeedback] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup); // Toggle popup visibility
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://mindlr.ai/api/feedback/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback }),
      });

      if (response.ok) {
        alert("Feedback submitted successfully!");
        setFeedback("");
        setShowPopup(false); // Close popup after submit
      } else {
        alert("Failed to submit feedback");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <div>
      {/* Feedback Button */}
      <button
        onClick={togglePopup} // Toggle the popup
        className="bg-blue-500 hover:bg-blue-700 text-white font-poppins py-2 px-4 rounded-full shadow-lg"
      >
        Feedback
      </button>

      {/* Conditionally render Feedback Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-poppins text-black mb-4">
              Kindly give us your feedback!
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Enter your feedback here..."
                  className="w-full p-2 border bg-slate-200 text-black rounded"
                  rows="5"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
