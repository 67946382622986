import React, { useState } from "react";

const AlphaDetailsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    instaId: "",
  });

  const [submitted, setSubmitted] = useState(false); // State to track submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://mindlr.ai/api/submit-details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true); // Set submission status to true
        setFormData({ name: "", email: "", instaId: "" });
      } else {
        console.error("Error submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-500">
      {/* Show only Thank You message after form submission */}
      {submitted ? (
        <div className="mt-4 text-center text-2xl font-poppins text-black dark:text-white">
          <p>Thanks for taking your time in filling the form.</p>
          <p>We'll get back to you under 24 hours</p>
          <p className="mt-2">- Team Mindlr</p>{" "}
          {/* "Team Mindlr" appears below */}
        </div>
      ) : (
        <>
          {/* Form and other elements are hidden after submission */}
          <div className="flex items-center space-x-2 mb-6">
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-16 w-auto"
            />
            <h1 className="text-white text-5xl font-poppins">Mindlr</h1>
          </div>

          <p className="text-white text-2xl font-poppins mb-2 text-center">
            Find an Automation in the Situation You are Dealing With
          </p>

          <form
            className="bg-black p-6 rounded-lg shadow-lg max-w-md w-4/5 md:w-full "
            onSubmit={handleSubmit}
          >
            <h2 className="text-2xl font-poppins font-bold mb-6 text-center text-white">
              Details Form
            </h2>

            {/* Name Field */}
            <div className="mb-4">
              <label className="block text-white text-sm font-poppins mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none text-black dark:text-white bg-white dark:bg-black"
                required
              />
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label className="block text-white text-sm font-poppins mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none text-black dark:text-white bg-white dark:bg-black"
                required
              />
            </div>

            {/* Instagram ID Field */}
            <div className="mb-6">
              <label className="block text-white text-lg font-poppins mb-2">
                Add your Instagram handle if you create content about AI!
              </label>
              <input
                type="text"
                name="instaId"
                value={formData.instaId}
                onChange={handleChange}
                placeholder="Enter your Instagram ID"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none text-black dark:text-white bg-white dark:bg-black"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default AlphaDetailsForm;
