import React, { useEffect, useState } from "react";

export default function TimeStats() {
  const [timeStats, setTimeStats] = useState([]);
  const [totalTime, setTotalTime] = useState(0);

  useEffect(() => {
    const fetchTimeStats = async () => {
      try {
        const response = await fetch("http://localhost:5001/api/getTimeStats");
        if (!response.ok) throw new Error("Failed to fetch time stats");

        const data = await response.json();

        // Calculate total time across all users
        const totalTimeSpent = data.reduce((acc, user) => {
          const userTotal = user.timeSpent.reduce(
            (sum, session) => sum + session.duration,
            0
          );
          return acc + userTotal;
        }, 0);

        setTotalTime(totalTimeSpent);
        setTimeStats(data);
      } catch (error) {
        console.error("Error fetching time stats:", error);
      }
    };

    fetchTimeStats();
  }, []);

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center text-teal-600 mb-6">
        Total Time Spent on Application: {formatDuration(totalTime)}
      </h1>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">User</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">
              Total Time Spent
            </th>
            <th className="border border-gray-300 px-4 py-2">Sessions</th>
          </tr>
        </thead>
        <tbody>
          {timeStats.map((user) => (
            <tr key={user._id}>
              <td className="border border-gray-300 px-4 py-2">{user.name}</td>
              <td className="border border-gray-300 px-4 py-2">{user.email}</td>
              <td className="border border-gray-300 px-4 py-2">
                {formatDuration(
                  user.timeSpent.reduce(
                    (sum, session) => sum + session.duration,
                    0
                  )
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <table className="w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-2 py-1">#</th>
                      <th className="border border-gray-300 px-2 py-1">
                        Session Start
                      </th>
                      <th className="border border-gray-300 px-2 py-1">
                        Session End
                      </th>
                      <th className="border border-gray-300 px-2 py-1">
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.timeSpent.map((session, index) => (
                      <tr key={index}>
                        <td className="border border-gray-300 px-2 py-1">
                          {index + 1}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {new Date(session.sessionStart).toLocaleString()}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {new Date(session.sessionEnd).toLocaleString()}
                        </td>
                        <td className="border border-gray-300 px-2 py-1">
                          {formatDuration(session.duration)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
