import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa"; // Assuming you're using Font Awesome for the plus icon

const RedirectHandler = () => {
  const navigate = useNavigate();
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    if (code) {
      fetch("/api/instagram/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to exchange token. Please try again.");
          }
          return response.json();
        })
        .then((data) => {
          setAccessToken(data.accessToken);
          setMedia(data.media);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Instagram media:", error);
          setError(error.message);
          setTimeout(() => navigate("/profile"), 5000);
        });
    } else {
      setTimeout(() => navigate("/profile"), 5000); // Redirect if no code is present
    }
  }, [navigate]);

  const handleMediaSelect = (id) => {
    setSelectedMedia((prevState) =>
      prevState.includes(id)
        ? prevState.filter((mediaId) => mediaId !== id)
        : [...prevState, id]
    );
  };

  const handleUpload = () => {
    fetch("https://mindlr.ai/api/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedMedia, accessToken }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload media. Please try again.");
        }
        return response.json();
      })
      .then(() => {
        navigate("/profile");
      })
      .catch((error) => console.error("Error uploading media:", error));
  };

  if (loading) {
    return (
      <div className="text-black font-bold">Loading Instagram content..</div>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <div className="bg-gray-200 p-4">
      {/* Header and Icons */}
      <div className="flex justify-between items-center mb-4">
        {/* Instagram icon */}
        <div className="text-gray-700 text-2xl">
          <i className="fab fa-instagram"></i>
        </div>
        {/* Title */}
        <h1 className="text-black text-3xl font-poppins text-center">
          Please select AI related content only!
        </h1>
        {/* Plus icon */}
        <div className="text-gray-700 text-2xl">
          <FaPlus />
        </div>
      </div>

      {/* Video Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
        {media.map(
          (item) =>
            item.media_type === "VIDEO" && (
              <div key={item.id} className="relative">
                <video
                  src={item.media_url}
                  controls
                  className="w-full h-auto object-cover"
                />
                <input
                  type="checkbox"
                  className="absolute top-2 right-2 w-6 h-6"
                  checked={selectedMedia.includes(item.id)}
                  onChange={() => handleMediaSelect(item.id)}
                />
                {/* Caption */}
                <p className="text-center text-black mt-2">
                  {item.caption || "No caption available"}
                </p>
              </div>
            )
        )}
      </div>

      {/* Upload Button */}
      <div className="text-center mt-6">
        <button
          onClick={handleUpload}
          className="bg-green-500 text-black font-poppins px-4 py-2 rounded-md"
        >
          Upload Selected Videos
        </button>
      </div>
    </div>
  );
};

export default RedirectHandler;
