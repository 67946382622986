import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function VideoCard({
  video,
  showUploader = true,
  query,
  handleVideoPlay,
}) {
  const videoRef = useRef(null); // Ref for the video element
  const commentDropdownRef = useRef(null); // Ref for the comment dropdown element
  const [isMuted, setIsMuted] = useState(false);
  const [likesCount, setLikesCount] = useState(video.likes?.length || 0);
  const [commentsCount, setCommentsCount] = useState(
    video.comments?.length || 0
  );
  const [comments, setComments] = useState([]);
  const [showCommentsDropdown, setShowCommentsDropdown] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [userId, setUserId] = useState(null);
  const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false); // State for feedback prompt
  const [videoPlaying, setVideoPlaying] = useState(false); // Track video playing state
  const navigate = useNavigate();

  // Timer reference for the feedback prompt
  let feedbackTimer = useRef(null);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch("https://mindlr.ai/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUserId(userData._id);
          console.log("Logged-in user ID:", userData._id);
        } else {
          console.error("Failed to fetch user ID");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    fetchUserId();
  }, []);

  const handleMuteToggle = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleLike = async () => {
    if (!userId) {
      console.error("User ID not available");
      return;
    }

    try {
      const response = await fetch(
        `https://mindlr.ai/api/videos/${video._id}/like`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      if (response.ok) {
        setLikesCount(likesCount + 1);
      } else {
        console.error("Failed to like the video:", response.statusText);
      }
    } catch (error) {
      console.error("Error liking the video:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await fetch(
        `https://mindlr.ai/api/videos/${video._id}/comments`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setComments(data);
        setCommentsCount(data.length);
        setShowCommentsDropdown(true);
      } else {
        console.error("Failed to fetch comments:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!userId || !newComment.trim()) {
      console.error("User ID or comment is missing.");
      return;
    }

    try {
      const response = await fetch(
        `https://mindlr.ai/api/videos/${video._id}/comment`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, comment: newComment }),
        }
      );

      if (response.ok) {
        const newCommentData = await response.json();
        const userResponse = await fetch(`https://mindlr.ai/api/user`, {
          method: "GET",
          credentials: "include",
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();
          newCommentData.user = userData;
          setComments((prevComments) => [newCommentData, ...prevComments]);
          setCommentsCount((prevCount) => prevCount + 1);
          setNewComment("");
          setShowCommentsDropdown(true);
        } else {
          console.error(
            "Failed to fetch user details:",
            userResponse.statusText
          );
        }
      } else {
        console.error("Failed to comment on the video:", response.statusText);
      }
    } catch (error) {
      console.error("Error commenting on the video:", error);
    }
  };

  const handleUserClick = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  // Function to handle feedback response
  const handleFeedback = async (response) => {
    try {
      const feedbackResponse = await fetch(
        "https://mindlr.ai/api/saveVideoFeedback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query, // Restored the query prop
            videoUrl: video.videoUrl,
            response,
          }),
        }
      );

      if (!feedbackResponse.ok) {
        throw new Error("Failed to send feedback");
      }
      console.log("Feedback saved");
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
    setShowFeedbackPrompt(false);
  };

  // Reset feedback prompt and clear timer when video or query changes
  useEffect(() => {
    setVideoPlaying(false);
    setShowFeedbackPrompt(false);

    if (feedbackTimer.current) {
      clearTimeout(feedbackTimer.current);
    }
  }, [video._id, query]);

  // Handle video play and feedback prompt
  useEffect(() => {
    if (videoPlaying && query) {
      feedbackTimer.current = setTimeout(() => {
        setShowFeedbackPrompt(true);
      }, 5000); // Show prompt after 5 seconds of video play
    }

    return () => {
      if (feedbackTimer.current) {
        clearTimeout(feedbackTimer.current);
      }
    };
  }, [videoPlaying, query]);

  // Toggle play/pause when clicking anywhere on the video container
  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        handleVideoPlay(videoElement); // Notify the parent to pause other videos
        videoElement.play();
        setVideoPlaying(true);
      } else {
        videoElement.pause();
        setVideoPlaying(false);
      }
    }
  };

  const videoUrl = video.videoUrl || video;
  const uploadedBy = video.uploadedBy || {
    name: "Unknown Uploader",
    profilePic: "/images/finalplaceholder.png",
    _id: "",
  };
  const caption = video.caption || "No Caption";

  return (
    <div className="bg-gray-600 rounded-lg shadow-md overflow-hidden h-auto w-full flex flex-col relative">
      {showUploader && (
        <div className="flex items-center p-4 bg-gray-600 border-b border-gray-500">
          <Link to={`/uploader-profile/${uploadedBy._id}`}>
            <img
              src={uploadedBy.profilePic}
              alt="Profile"
              className="rounded-full w-12 h-12 mr-4"
            />
          </Link>
          <div>
            <Link to={`/uploader-profile/${uploadedBy._id}`}>
              <h3 className="text-lg text-white font-poppins">
                {uploadedBy.name}
              </h3>
            </Link>
          </div>
        </div>
      )}

      {/* Video section with click to play/pause */}
      <div
        className="relative bg-black flex items-center justify-center cursor-pointer"
        onClick={togglePlayPause} // Toggle play/pause on click
      >
        <video
          ref={videoRef}
          className="w-full h-auto object-contain rounded-none"
          src={videoUrl}
        >
          Your browser does not support the video tag.
        </video>

        {/* Feedback prompt that appears after 5 seconds of play */}
        {showFeedbackPrompt && query && (
          <div className="absolute top-0 left-0 w-full bg-opacity-80 bg-black flex items-center text-white p-3 rounded-b-md">
            <p className="mr-4 font-poppins text-sm">
              Is this video relevant to the search?
            </p>
            <div className="flex space-x-4">
              <button
                className="bg-green-500 text-white px-3 py-1 rounded-full text-sm"
                onClick={() => handleFeedback("relevant")}
              >
                ✔
              </button>
              <button
                className="bg-red-500 text-white px-3 py-1 rounded-full text-sm"
                onClick={() => handleFeedback("not relevant")}
              >
                ✘
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Caption Section */}
      <div className="p-3 bg-gray-600 text-gray-200 text-sm text-center font-poppins border-t border-gray-500">
        {caption}
      </div>

      {/* Interaction Icons */}
      <div className="flex items-center justify-around p-4 bg-gray-600 border-t border-gray-500">
        <div className="flex items-center">
          <span
            className="material-icons text-gray-300 cursor-pointer hover:text-white"
            onClick={handleLike}
            style={{ fontSize: "20px" }}
          >
            thumb_up
          </span>
          <span className="ml-2 text-gray-300 text-sm font-poppins">
            {likesCount}
          </span>
        </div>
        <div className="relative flex items-center">
          <span
            className="material-icons text-gray-300 cursor-pointer hover:text-white"
            onClick={fetchComments}
            style={{ fontSize: "20px" }}
          >
            chat_bubble
          </span>
          <span className="ml-2 text-gray-300 text-sm font-poppins">
            {commentsCount}
          </span>
        </div>
        <span
          className="material-icons text-gray-300 hover:text-white cursor-pointer"
          style={{ fontSize: "20px" }}
        >
          share
        </span>
        <span
          className="material-icons text-gray-300 hover:text-white cursor-pointer"
          style={{ fontSize: "20px" }}
        >
          star
        </span>
      </div>

      {/* Comments Dropdown */}
      {showCommentsDropdown && (
        <div
          ref={commentDropdownRef}
          className="absolute top-1/2 left-0 bg-gray-700 shadow-lg rounded-lg p-4 w-full h-1/2 overflow-y-auto z-50"
        >
          <div className="flex justify-end">
            <span
              className="material-icons text-white cursor-pointer"
              onClick={() => setShowCommentsDropdown(false)}
            >
              close
            </span>
          </div>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment._id} className="text-white font-poppins mb-2">
                <div className="flex items-center">
                  <img
                    src={comment.user?.profilePic}
                    alt="Profile"
                    className="rounded-full w-8 h-8 mr-4 cursor-pointer"
                    onClick={() => handleUserClick(comment.user?._id)}
                  />
                  <div>
                    <strong className="text-gray-200">
                      {comment.user?.name || "Unknown User"}
                    </strong>
                    <p className="text-gray-300">{comment.comment}</p>
                  </div>
                </div>
                <hr className="my-2 border-t border-gray-500" />
              </div>
            ))
          ) : (
            <p className="text-gray-300 font-poppins">No comments yet.</p>
          )}
          <form
            className="flex items-center mt-4"
            onSubmit={handleCommentSubmit}
          >
            <input
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
              className="flex-1 p-2 border border-gray-500 rounded-full bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <button
              type="submit"
              className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-full"
            >
              Post
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
