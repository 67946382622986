import React, { useState, useEffect } from "react";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FiUserPlus } from "react-icons/fi"; // Follow icon
import { useNavigate, Link } from "react-router-dom";
import WelcomePopup from "../components/WelcomePopup";

export default function People() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null); // Logged-in user info
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `https://mindlr.ai/api/people?query=${encodeURIComponent(query)}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Users fetched from backend:", data); // Log the users data
          setUsers(data);
        } else {
          console.error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [query]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://mindlr.ai/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          console.log("Logged-in user info:", userData); // Log the logged-in user's data
          setUser(userData);
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSearch = () => {
    navigate(`/home/people?query=${encodeURIComponent(query)}`);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Top Bar */}
      <div className="hidden md:flex items-center justify-between bg-gray-500 p-4 shadow-md">
        <div className="flex items-center justify-center w-full md:w-3/4 mx-auto md:ml-64">
          <div className="relative flex items-center">
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-10 w-10 mx-2 hidden md:block"
              style={{ marginBottom: "0.5rem" }}
            />
            <WelcomePopup />
          </div>

          <div className="relative w-full max-w-3xl h-12">
            <BsStars className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 text-2xl md:text-3xl" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for people"
              className="h-full pl-16 bg-white w-full border border-gray-300 rounded-xl text-gray-800 pr-12 text-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
            />
            <CiSearch
              onClick={handleSearch}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 text-3xl md:text-4xl"
            />
          </div>
        </div>

        <div className="flex justify-around w-1/4 space-x-4">
          <Link to="/profile">
            <span className="material-icons text-gray-500 text-3xl md:text-4xl hover:text-gray-400">
              person
            </span>
          </Link>
          <span className="material-icons text-gray-500 text-3xl md:text-4xl cursor-pointer hover:text-gray-400">
            settings
          </span>
        </div>
      </div>

      <div className="flex">
        {/* Left Sidebar */}
        <div className="hidden md:flex bg-gray-200 shadow-md w-1/5 rounded-lg p-4 flex-col items-center justify-start">
          <div className="flex flex-col items-center mt-4">
            <img
              src={user?.profilePic || "/images/defaultprofpic1.jpg"}
              alt="Profile"
              className="rounded-full w-24 h-24 mb-4 border border-gray-300"
            />
            <h3 className="text-md font-poppins font-light text-gray-800">
              {user?.name}
            </h3>
            <p className="text-sm text-gray-500">
              {user?.bio || "No bio available"}
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="bg-slate-200 rounded-lg ">
            {users.map((user, index) => (
              <div
                key={user._id}
                className={`flex items-center justify-between p-4 ${
                  index !== users.length - 1 ? "border-b border-gray-300" : ""
                }`}
              >
                <div className="flex items-center">
                  <img
                    src={user.profilePic || "/images/defaultprofpic1.jpg"}
                    alt="Profile"
                    className="rounded-full w-14 h-14 border border-gray-300"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-poppins font-light text-gray-800">
                      {user.name}
                    </h3>
                    <p className="text-sm text-gray-500">{user.bio}</p>

                    {/* Tools Section */}
                    <div className="flex flex-wrap mt-2">
                      {user.toolbox.map((tool, index) => (
                        <a
                          key={index}
                          href={tool.tool?.Link || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="w-8 h-8 m-1"
                        >
                          <img
                            src={tool.tool?.Image || "/images/defaulttool.png"}
                            alt={tool.tool?.Name || "Tool"}
                            className="w-full h-full rounded-full border border-gray-300"
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mr-4">
                  <FiUserPlus className="text-gray-500 text-2xl cursor-pointer hover:text-gray-400" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
