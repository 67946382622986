import React, { useState, useEffect, useRef } from "react";
import VideoCard from "../components/VideoCard";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { useNavigate, Link } from "react-router-dom";
import NotificationPopup from "../components/NotificationPopup";
import Disclaimer from "../components/Disclaimer";
import FeedbackForm from "../components/FeedbackForm";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import WelcomePopup from "../components/WelcomePopup";
import ToolBuyPopup from "../components/ToolBuyPopup";
import { FaCoins } from "react-icons/fa";

export default function WelcomePage() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showTestingText, setShowTestingText] = useState(false); // New state
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false); // Initial state to show the disclaimer
  const navigate = useNavigate();
  const profilePicInputRef = useRef(null);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [textualResults, setTextualResults] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState(null);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [showRewritePrompt, setShowRewritePrompt] = useState(false); // Control to show or hide rewritten prompt
  const [threeSuggestions, setThreeSuggestions] = useState([]); // Store the rewritten prompt suggestions
  const [manualSearch, setManualSearch] = useState(false);
  const [blueprintData, setBlueprintData] = useState(null);
  const [toolImages, setToolImages] = useState([]);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]); // Store suggestions
  const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility
  const [isFlipped, setIsFlipped] = useState(false);
  const [customizePrompt, setCustomizePrompt] = useState("");
  const [customizedBlueprint, setCustomizedBlueprint] = useState([]);
  const [credits, setCredits] = useState(0);
  const [showBuyPopup, setShowBuyPopup] = useState(false);
  const settingsRef = useRef(null);
  const notificationsRef = useRef(null);
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState(null); // Track the currently playing video

  const handleVideoPlay = (videoRef) => {
    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoRef) {
      currentlyPlayingVideo.pause(); // Pause the previously playing video
    }
    setCurrentlyPlayingVideo(videoRef); // Set the new currently playing video
  };
  // Function to handle "Agree" action in the disclaimer

  // Check if disclaimer has already been accepted
  useEffect(() => {
    const isDisclaimerAccepted = localStorage.getItem("disclaimerShown");
    if (isDisclaimerAccepted) {
      setDisclaimerAccepted(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://mindlr.ai/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          fetchNotificationCount(userData._id); // Fetch notifications count
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  //time spent
  useEffect(() => {
    if (!user || !user.email) {
      console.warn("User is not defined or email is missing.");
      return;
    }

    const sessionStart = Date.now(); // Record session start time

    const logSessionTime = async () => {
      const sessionEnd = Date.now(); // Record session end time
      const sessionDuration = Math.floor((sessionEnd - sessionStart) / 1000); // Calculate in seconds

      console.log("Session data being logged:", {
        email: user?.email,
        sessionStart,
        sessionEnd,
        sessionDuration,
      });

      try {
        const response = await fetch(
          "https://mindlr.ai/api/log-session-time",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: user?.email, // Ensure this is correctly populated
              sessionStart,
              sessionEnd,
              sessionDuration,
            }),
          }
        );

        if (response.ok) {
          console.log("Session logged successfully.");
        } else {
          const errorData = await response.json();
          console.error("Failed to log session time:", errorData.message);
        }
      } catch (error) {
        console.error("Failed to log session time:", error);
      }
    };

    // Attach the event listener for page unload
    window.addEventListener("beforeunload", logSessionTime);

    // Cleanup the event listener when the component unmounts
    return () => {
      logSessionTime(); // Log session time before unmount
      window.removeEventListener("beforeunload", logSessionTime);
    };
  }, [user]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await fetch("https://mindlr.ai/api/get-credits", {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setUser((prevUser) => ({
            ...prevUser,
            credits: data.credits,
          }));
        } else {
          console.error("Failed to fetch credits");
        }
      } catch (error) {
        console.error("Error fetching credits:", error);
      }
    };

    fetchUserCredits();
  }, []);

  const checkAuthCookie = () => {
    return document.cookie.includes("token"); // Replace 'authToken' with your cookie name
  };

  useEffect(() => {
    const isAuthenticated = checkAuthCookie();

    if (isAuthenticated && !localStorage.getItem("disclaimerShown")) {
      // Show disclaimer only if the user is authenticated and hasn't seen the disclaimer yet
      setShowDisclaimer(true);
    }
  }, []);

  const handleAgree = () => {
    setShowDisclaimer(false);
    // Set the localStorage flag so disclaimer doesn't show again during this session
    localStorage.setItem("disclaimerShown", "true");
    setDisclaimerAccepted(true);
  };

  const handleDisagree = () => {
    // Handle logic if the user disagrees
  };

  useEffect(() => {
    setSearchTriggered(true); // Mark search as triggered without a default search
  }, []);

  // Use another effect for handling the search when the query changes

  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        "https://mindlr.ai/api/notifications/count",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
      } else {
        console.error("Failed to fetch notification count");
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  const handleNotificationClick = async () => {
    setShowNotificationPopup((prev) => !prev); // Toggle notification popup
    setNotificationsOpen((prev) => !prev);
    try {
      const response = await fetch(
        "https://mindlr.ai/api/notifications/mark-read",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: user._id }),
        }
      );

      if (response.ok) {
        setNotificationCount(0);
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  //live name suggestions
  /*const fetchNameSuggestions = async (query) => {
    if (!query.trim()) {
      setNameSuggestions([]); // Clear suggestions if query is empty
      setShowDropdown(false);
      return;
    }

    try {
      const response = await fetch(
        `https://mindlr.ai/api/people/suggestions?query=${encodeURIComponent(
          query
        )}`, // Backend endpoint for suggestions
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNameSuggestions(data); // Update suggestions
        setShowDropdown(true); // Show dropdown
      } else {
        console.error("Failed to fetch name suggestions");
      }
    } catch (error) {
      console.error("Error fetching name suggestions:", error);
    }
  };*/

  const handleSearch = async (searchQuery = query, retry = false) => {
    localStorage.removeItem("latestBlueprint");

    setLoading(true);
    setError(null);
    setSearchSubmitted(true);
    setLastSearchQuery(searchQuery);
    setAllImagesLoaded(false);

    try {
      const bpUrl = `https://app.mindlr.ai/searchblueprint?query=${encodeURIComponent(
        searchQuery
      )}`;
      const apiUrl = `https://app.mindlr.ai/search?query=${encodeURIComponent(
        searchQuery.trim()
      )}`;

      const [blueprintResponse, mainResponse] = await Promise.all([
        fetch(bpUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }),
        fetch(apiUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }),
      ]);

      if (!blueprintResponse.ok || !mainResponse.ok) {
        throw new Error("Failed to fetch blueprint or main search results");
      }

      const blueprintData = await blueprintResponse.json();
      const searchData = await mainResponse.json();

      if (blueprintData.length === 0 && !retry) {
        return handleSearch(searchQuery, true);
      }

      const toolNames = blueprintData.map((step) => step.tool_name);
      const toolImagesResponse = await fetch(
        "https://mindlr.ai/api/getToolImages",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ toolNames }),
        }
      );

      if (!toolImagesResponse.ok) {
        throw new Error("Failed to fetch tool images");
      }

      const toolImagesData = await toolImagesResponse.json();
      setBlueprintData(blueprintData);
      localStorage.setItem("latestBlueprint", JSON.stringify(blueprintData));

      setToolImages(toolImagesData);

      const imagePromises = toolNames.map(
        (toolName) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = toolImagesData[toolName] || "";
            img.onload = resolve;
            img.onerror = resolve;
          })
      );
      await Promise.all(imagePromises);
      setAllImagesLoaded(true);

      const savePayload = {
        query: searchQuery,
        blueprint: blueprintData,
        user: {
          name: user.name, // Retrieved earlier via /api/user
          email: user.email,
        },
      };

      const saveResponse = await fetch("https://mindlr.ai/api/saveSearch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(savePayload),
      });

      if (!saveResponse.ok) {
        console.error("Failed to save blueprint and query to the database");
      }

      setTextualResults(searchData.textual_results);

      const videoNames = searchData.video_results.map((name) =>
        name.endsWith(".mp4") ? name : `${name}.mp4`
      );

      const videoResponse = await fetch(
        "https://mindlr.ai/api/getsearchVideos",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ videoNames, query: searchQuery }),
        }
      );

      if (!videoResponse.ok) {
        throw new Error("Failed to fetch video results");
      }

      const videoData = await videoResponse.json();
      setResults(videoData);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      setError("Failed to fetch blueprint or tool images");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearchClicked = () => {
    handleSearch();
  };

  const handleProfilePicUpload = async (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);

    try {
      const response = await fetch(
        "https://mindlr.ai/api/user/profile-pic",
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        console.log("Updated User:", updatedUser);
      } else {
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const toggleSettings = () => {
    //setSettingsOpen(!settingsOpen);
    setSettingsOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    localStorage.removeItem("disclaimerShown");
    localStorage.removeItem("hasShownWelcomePopup");
    localStorage.removeItem("latestBlueprint");

    try {
      const response = await fetch("https://mindlr.ai/api/logout", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        navigate("/sign-in");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch("https://mindlr.ai/api/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This is important to send the cookies with the request
        body: JSON.stringify({
          userId: user._id, // Send the user ID in the request body
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        // Try to parse the response as JSON
        try {
          const data = await response.json();
          alert(`Failed to update password: ${data.message}`);
        } catch (jsonError) {
          // If parsing fails, display a general error message
          alert("Failed to update password: An error occurred.");
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const handleRewritePromptClick = async () => {
    try {
      const response = await fetch("https://mindlr.ai/api/rewrite-query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }), // Send the current query
      });

      if (response.ok) {
        const data = await response.json();
        const suggestionsArray = data.data.split("\n").filter(Boolean); // Adjust this split logic if necessary
        setThreeSuggestions(suggestionsArray); // Save the rewritten suggestions
        setShowRewritePrompt(true); // Show the suggestions box
      } else {
        console.error("Error fetching query rewrites");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion); // Set the query to the clicked suggestion
    handleSearch(); // Trigger the search with the new suggestion
  };

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const TypingText = ({ text }) => {
    return <p>{text}</p>; // Just return the entire sentence
  };

  const TextualResultsWithTyping = ({ textualResults }) => {
    const [activeTextIndex, setActiveTextIndex] = useState(0);

    // Split the textual results by sentences (splitting on periods, exclamation marks, or question marks followed by a space)
    const sentences = textualResults.split(/(?<=[.?!])\s+/);

    useEffect(() => {
      if (activeTextIndex < sentences.length) {
        const timer = setTimeout(() => {
          setActiveTextIndex((prev) => prev + 1);
        }, 1000); // Delay between sentences (you can adjust the 1000ms to make it faster/slower)

        return () => clearTimeout(timer); // Cleanup on unmount
      }
    }, [activeTextIndex, sentences]);

    useEffect(() => {
      const savedBlueprint = localStorage.getItem("latestBlueprint");
      if (savedBlueprint) {
        setBlueprintData(JSON.parse(savedBlueprint));
        setAllImagesLoaded(true); // Assuming all images are pre-loaded when retrieving from storage
      }
    }, []);

    return (
      <div className="textual-results p-4 mb-4 text-black font-poppins bg-gray-100 rounded-md">
        {/* Display each sentence progressively */}
        {sentences.slice(0, activeTextIndex + 1).map((sentence, index) => (
          <TypingText key={index} text={sentence.trim()} />
        ))}
      </div>
    );
  };
  const handleCustomizeBlueprint = async () => {
    console.log("Customization button clicked, processing request...");

    // Check for sufficient credits
    if (user?.credits <= 0) {
      setShowBuyPopup(true);
      return;
    }

    // Ensure customization prompt is entered
    if (!customizePrompt.trim()) {
      alert("Please enter a customization prompt.");
      return;
    }

    const payload = {
      query: lastSearchQuery, // The original search query
      previous_blueprint: blueprintData.map((step) => ({
        step: step.step,
        explanation: step.explanation || "N/A",
        instruction: step.instruction || "N/A",
        tool_name: step.tool_name || "N/A",
        tool_link: step.tool_link || "N/A",
        tool_logo: step.tool_logo || "N/A",
      })),
      prompt: customizePrompt, // Customization prompt entered by the user
    };

    setLoading(true); // Show spinner

    try {
      console.log("Payload sent to API:", payload);

      // Send the customization request
      const response = await fetch(
        "https://app.themindlr.com/customizeblueprint",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log("Response from API:", data);

      // Set the new customized blueprint data
      setBlueprintData(data);
      localStorage.setItem("latestBlueprint", JSON.stringify(data));

      // Save the customization to the backend
      try {
        const saveCustomizationResponse = await fetch(
          "https://mindlr.ai/api/saveCustomization",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: lastSearchQuery,
              customizePrompt,
              customizedBlueprint: data,
              user: {
                name: user.name, // Assuming `user` is the logged-in user's object
                email: user.email, // Include user's email
              },
            }),
          }
        );

        if (!saveCustomizationResponse.ok) {
          console.error("Failed to save customization to the database.");
        } else {
          console.log("Customization successfully saved to the database.");
        }
      } catch (error) {
        console.error("Error saving customization:", error);
      }

      // Deduct credits by calling the /api/deduct-credits endpoint
      const deductCreditsResponse = await fetch(
        "https://mindlr.ai/api/deduct-credits",
        {
          method: "POST",
          credentials: "include", // Include cookies for authentication
        }
      );

      if (!deductCreditsResponse.ok) {
        const errorData = await deductCreditsResponse.json();
        if (errorData.message === "Insufficient credits") {
          alert("You need to purchase more credits to customize further.");
          navigate("/toolmaker-welcome");
          return;
        }
        throw new Error("Failed to deduct credits.");
      }

      // Fetch the updated credits and update the UI
      const creditsResponse = await fetch(
        "https://mindlr.ai/api/get-credits",
        {
          method: "GET",
          credentials: "include", // Include cookies for authentication
        }
      );

      if (creditsResponse.ok) {
        const creditsData = await creditsResponse.json();
        setUser((prevUser) => ({
          ...prevUser,
          credits: creditsData.credits,
        }));
      } else {
        console.error("Failed to fetch updated credits.");
      }

      // Reset states after successful customization
      setCustomizePrompt(""); // Clear the customize prompt
      setIsFlipped(false); // Flip back the card to the original state
    } catch (error) {
      console.error("Error during customization:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Hide spinner
    }
  };
  useEffect(() => {
    const savedBlueprint = localStorage.getItem("latestBlueprint");
    if (savedBlueprint) {
      setBlueprintData(JSON.parse(savedBlueprint)); // Set the saved blueprint to state
      setAllImagesLoaded(true); // Ensure all images are marked as loaded
    }
  }, []);

  return (
    <div className="bg-gray-200 min-h-screen p-0">
      {/* Top Bar for Desktop View */}
      <div className="hidden md:flex flex-col bg-gray-200 p-4 rounded-none shadow-md">
        {/* Main Content of Top Bar */}
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center w-full md:w-3/4 mx-auto md:ml-64">
            <div className="relative flex items-center">
              {/* Mindlr Logo */}
              <img
                src="/images/mindrllogo.png"
                alt="Mindlr Logo"
                className="h-11 w-15 mx-2 mt-1 hidden md:block"
                style={{ marginBottom: "0.5rem" }}
              />
              {/* WelcomePopup next to logo */}
              <WelcomePopup />
            </div>

            {/* Search Bar */}
            <div className="relative w-full max-w-3xl h-12">
              <BsStars className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-2xl md:text-3xl" />
              <input
                type="text"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value); // Update query state
                  //fetchNameSuggestions(e.target.value); // Fetch suggestions
                }}
                onKeyDown={handleKeyPress}
                placeholder="Describe a situation to discover AI integrations"
                className="h-full pl-16 bg-gray-200  w-full border-2 border-gray-500 rounded-xl text-gray-800 pr-12 text-lg focus:outline-none"
              />
              <CiSearch
                onClick={handleSearchClicked}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400 text-3xl md:text-4xl"
              />
              {/* Dropdown for Suggestions */}
              {showDropdown && nameSuggestions.length > 0 && (
                <div
                  className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg z-50"
                  style={{ maxHeight: "250px", overflowY: "auto" }} // Optional, if more flexibility is needed
                >
                  {nameSuggestions.map((suggestion) => (
                    <div
                      key={suggestion._id}
                      onClick={() => {
                        setQuery(""); // Clear the query
                        setShowDropdown(false); // Hide the dropdown
                        navigate(
                          `/home/people?query=${encodeURIComponent(
                            suggestion.name
                          )}`
                        ); // Navigate
                      }}
                      className="p-2 hover:bg-gray-200 cursor-pointer text-gray-800 flex items-center"
                    >
                      <img
                        src={suggestion.profilePic}
                        alt={suggestion.name}
                        className="w-8 h-8 rounded-full mr-3"
                      />
                      {suggestion.name}
                      <div className="ml-auto flex space-x-2">
                        {suggestion.toolbox &&
                          suggestion.toolbox
                            .slice(0, 3)
                            .map((tool, index) => (
                              <img
                                key={index}
                                src={tool.image}
                                alt={tool.name}
                                className="w-6 h-6 rounded-full"
                              />
                            ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Icons (Profile, Notifications, Settings) */}
          <div className="flex justify-around w-1/4 space-x-4">
            <Link to="/profile">
              <span className="material-icons text-gray-600 text-3xl md:text-4xl hover:text-gray-400">
                person
              </span>
            </Link>
            <div className="relative">
              <span
                className="material-icons text-gray-600 text-3xl md:text-4xl cursor-pointer hover:text-gray-400"
                onClick={handleNotificationClick}
                ref={notificationsRef}
              >
                notifications
              </span>
              {notificationCount > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-4px",
                    right: "20%",
                    background: "red",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "12px",
                  }}
                >
                  {notificationCount}
                </span>
              )}
              {showNotificationPopup && (
                <NotificationPopup
                  userId={user?._id}
                  onClose={() => setShowNotificationPopup(false)}
                />
              )}
            </div>
            <span
              className="material-icons text-gray-600 text-3xl md:text-4xl cursor-pointer hover:text-gray-400"
              onClick={toggleSettings}
              ref={settingsRef}
            >
              settings
            </span>
            {settingsOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg z-50 border border-gray-600">
                <button
                  onClick={() => {
                    setShowResetPasswordPopup(true);
                    setTimeout(() => setSettingsOpen(false), 100);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-gray-600"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => {
                    setShowLogoutPopup(true);
                    setTimeout(() => setSettingsOpen(false), 100);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-gray-600"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Buttons Section */}
        <div className="flex justify-center space-x-4 mt-2">
          <button
            onClick={() => navigate("/home")}
            className="px-4 py-2 bg-cardbg9 text-white font-poppins  rounded-full hover:bg-gray-400 focus:outline-none transition-colors"
          >
            Home
          </button>
          <button
            onClick={() => navigate("/home/people")}
            className="px-4 py-2 bg-cardbg9 text-white font-poppins rounded-full  focus:outline-none transition-colors"
          >
            People
          </button>
        </div>
      </div>

      {/* Top Bar for Mobile View */}
      <div className="bg-gray-500 p-3 px-2 rounded-none md:hidden fixed top-0 left-0 w-full z-50">
        <div className="flex items-center justify-start w-full">
          {/* Profile Picture for Mobile View */}
          <img
            src="/images/mindrllogo.png"
            alt="Mindlr Logo"
            className="h-9 w-14 mx-2 mt-2 md:mt-0"
            style={{ marginBottom: "0.5rem" }}
          />
          <div className="relative w-full max-w-3xl h-12 ml-0">
            {" "}
            {/* Adjusted margin to better center the search bar */}
            <BsStars className="absolute left-3 top-1/2 transform -translate-y-1/2 text-black text-2xl md:text-3xl" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="type anything"
              className="h-full pl-14 bg-gray-700 w-full border border-gray-600 rounded-2xl text-white pr-12 text-lg"
            />
            <CiSearch
              onClick={handleSearchClicked}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-black text-3xl md:text-4xl"
            />
          </div>
        </div>
      </div>

      {/* Sticky Footer for Mobile View */}
      <div>
        {/* Conditionally render the Disclaimer component */}
        {!disclaimerAccepted && (
          <Disclaimer onAgree={handleAgree} onDisagree={handleDisagree} />
        )}

        {/* Sticky Footer - only show after the disclaimer is accepted */}
        {disclaimerAccepted && (
          <div className="fixed bottom-0 left-0 w-full bg-slate-200 p-4 flex justify-between items-center md:hidden z-50">
            <Link to="/profile">
              <img
                src={user?.profilePic || "/images/defaultprofpic1.jpg"}
                className="rounded-full items-stretch  w-10 h-10 md:w-28 md:h-28  cursor-pointer"
              />
            </Link>

            <div className="relative">
              <span
                className="material-icons text-black cursor-pointer"
                style={{ fontSize: "32px" }}
                onClick={handleNotificationClick}
              >
                notifications
              </span>
              {notificationCount > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-6px", // Adjust this value to move the notification count up or down
                    right: "-10px", // Adjust this value to move the notification count left or right
                    background: "red",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "14px",
                  }}
                >
                  {notificationCount}
                </span>
              )}
              {showNotificationPopup && (
                <NotificationPopup
                  userId={user?._id}
                  onClose={() => setShowNotificationPopup(false)}
                  position="up"
                />
              )}
            </div>

            <div className="relative">
              <span
                className="material-icons text-black cursor-pointer"
                style={{ fontSize: "32px" }}
                onClick={toggleSettings}
              >
                settings
              </span>

              {settingsOpen && (
                <div className="absolute bottom-14 right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                  <button
                    onClick={() => setShowResetPasswordPopup(true)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Reset Password
                  </button>
                  <button
                    onClick={() => setShowLogoutPopup(true)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Log Out
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex">
        {/* Left Sidebar for Desktop */}
        <div className="hidden md:flex mb-1 ml-1 py-4 mt-1 rounded-lg bg-gray-300 shadow-md w-1/6 p-4 flex-col items-center justify-start">
          <div className="flex flex-col items-center mt-4">
            <img
              src={user?.profilePic || "/images/defaultprofpic1.jpg"}
              alt="Profile"
              className="rounded-full w-24 h-24 mb-4 border border-gray-300"
            />
            <h3 className="text-md font-poppins font-light text-gray-800">
              {user?.name}
            </h3>
            <p className="text-sm text-gray-500">
              {user?.bio || "No bio available"}
            </p>
            {/* Credits Display */}
            <div className="flex items-center px-4 py-2 mt-4 bg-gray-200 text-gray-800 font-poppins rounded-lg shadow-md">
              <FaCoins className="w-5 h-5 mr-2 text-yellow-500" />
              <span>{user?.credits || 0} Credits</span>
            </div>
            <input
              type="file"
              id="profilePicInput"
              ref={profilePicInputRef}
              style={{ display: "none" }}
              onChange={handleProfilePicUpload}
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-1 rounded-none relative">
          <style>
            {`
      /* Add this CSS for scrollbar customization */
      ::-webkit-scrollbar {
        width: 12px; /* Increased scrollbar width */
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5); /* Color of the scrollbar */
        border-radius: 10px; /* Rounded scrollbar */
      }
      ::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
      }
    `}
          </style>

          {loading && (
            <div className="flex justify-center items-start pt-4">
              <div className="w-12 h-12 md:w-18 h-18 border-4 border-green-300 border-t-transparent rounded-full animate-spin mt-16 md:mt-0"></div>
            </div>
          )}

          {error && <div>{error}</div>}
          <div className="mt-20 md:mt-0 text-black font-poppins mx-auto w-full bg-cardbg10 shadow-md p-4 rounded-lg">
            {blueprintData && allImagesLoaded ? (
              <div
                className={`w-full p-4 rounded-lg bg-cardbg9 shadow-md border border-sunrise relative transform transition-transform duration-500 ${
                  isFlipped ? "rotate-y-180" : ""
                }`}
                style={{ perspective: "1000px" }}
              >
                {!isFlipped ? (
                  <div className="backface-hidden">
                    <h2 className="text-lg mb-4 text-center text-white font-poppins">
                      Step-by-Step Blueprint
                    </h2>
                    {blueprintData.map((step, index) => (
                      <div
                        key={index}
                        className="flex items-center mb-4 relative"
                      >
                        {step.tool_name !== "N/A" && (
                          <div className="flex flex-col items-center w-16">
                            <a
                              href={step.tool_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-10 h-10 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                            >
                              {step.tool_logo ? (
                                <img
                                  src={step.tool_logo}
                                  alt={step.tool_name}
                                  className="w-full h-full object-contain rounded-full"
                                />
                              ) : (
                                <span className="text-xs font-bold">
                                  {step.tool_name[0]}
                                </span>
                              )}
                            </a>
                            <div className="text-xs text-white font-bold font-poppins mt-1 text-center">
                              {step.tool_name}
                            </div>
                          </div>
                        )}
                        <div
                          className={`${
                            step.tool_name !== "N/A" ? "ml-4" : ""
                          } flex-1 bg-gray-300 p-2 rounded-lg border border-gray-500`}
                        >
                          <p className="text-sm text-gray-700 font-poppins font-semibold">
                            {step.instruction}
                          </p>
                        </div>
                        {index < blueprintData.length - 1 && (
                          <div
                            className="absolute left-8 border-l-2 border-gray-500 z-0"
                            style={{
                              top: "calc(100% + 8px)",
                              height: "20px",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                    <button
                      onClick={() => {
                        setIsFlipped(true);
                        setCustomizePrompt(""); // Clear the customization input field
                      }}
                      className="absolute top-4 right-4 px-3 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500 focus:outline-none"
                    >
                      Customize Blueprint
                    </button>
                  </div>
                ) : (
                  <div className="backface-hidden">
                    <h2 className="text-lg font-poppins mb-4 text-center text-gray-200">
                      Customize Your Blueprint
                    </h2>
                    {blueprintData.map((step, index) => (
                      <div
                        key={index}
                        className="flex items-center mb-6 p-4  rounded-lg border border-gray-500"
                      >
                        {step.tool_name !== "N/A" && (
                          <div className="flex flex-col items-center w-20">
                            <a
                              href={step.tool_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-12 h-12 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                            >
                              {step.tool_logo ? (
                                <img
                                  src={step.tool_logo}
                                  alt={step.tool_name}
                                  className="w-full h-full object-contain rounded-full"
                                />
                              ) : (
                                <span className="text-xs font-bold">
                                  {step.tool_name[0]}
                                </span>
                              )}
                            </a>
                            <div className="text-xs text-gray-300 mt-2 font-poppins text-center">
                              {step.tool_name}
                            </div>
                          </div>
                        )}
                        <div className="flex-1 ml-4">
                          <div className="bg-gray-700 p-2 rounded-lg border border-gray-600">
                            <p className="text-sm text-gray-200">
                              {step.instruction}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <textarea
                      placeholder="Enter your prompt to customize"
                      className="w-full p-3 bg-gray-800 text-white rounded-lg border border-gray-500 mb-4"
                      value={customizePrompt}
                      onChange={(e) => setCustomizePrompt(e.target.value)}
                    />
                    <button
                      onClick={handleCustomizeBlueprint}
                      className="px-3 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500 focus:outline-none"
                    >
                      Submit Customization
                    </button>
                    <button
                      onClick={() => {
                        setIsFlipped(false);
                        setCustomizePrompt(""); // Clear input field
                      }}
                      className=" ml-2 px-3 py-2 bg-red-400 text-white rounded-md hover:bg-gray-500 focus:outline-none mt-2"
                    >
                      Cancel
                    </button>
                    {loading && (
                      <div className="mt-4 text-center">
                        <div className="w-10 h-10 border-4 border-teal-300 border-t-transparent rounded-full animate-spin mx-auto"></div>
                        <p className="text-gray-200 mt-2">Processing...</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              !searchSubmitted && (
                <div className="w-full p-4 rounded-lg bg-cardbg10 border border-gray-600">
                  <h2 className="text-lg font-poppins mb-4 text-center text-gray-200">
                    Generate Workflow Blueprints
                  </h2>
                  <p className="text-center text-gray-300">
                    Welcome to Mindlr. Describe a task or situation where AI
                    could assist, and generate a customizable workflow blueprint
                    with recommended tools. Experiment with them in our
                    playground and integrate them into your workflow.
                  </p>
                </div>
              )
            )}

            {/* Render the ToolBuyPopup component when showBuyPopup is true */}
            {showBuyPopup && (
              <ToolBuyPopup
                onClose={() => setShowBuyPopup(false)}
                onNavigate={() => navigate("/toolmaker-welcome")}
              />
            )}
          </div>

          {/* Video Results Section */}
          {results && results.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              {results.map((video, index) => (
                <VideoCard
                  key={index}
                  video={video}
                  query={lastSearchQuery}
                  handleVideoPlay={handleVideoPlay}
                />
              ))}
            </div>
          ) : (
            // No videos message
            !loading &&
            searchSubmitted &&
            results &&
            results.length === 0 && (
              <div className="text-black font-poppins text-center mt-4">
                We currently don't have any user experiences for this situation.
              </div>
            )
          )}

          {/* Video Results Section */}
          {results && results.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {results.map((video, index) => (
                <VideoCard
                  key={index}
                  video={video}
                  query={lastSearchQuery}
                  handleVideoPlay={() => {}}
                />
              ))}
            </div>
          ) : (
            // Display the "no videos" message only if search has been submitted and results are empty
            !loading &&
            searchSubmitted &&
            results &&
            results.length === 0 && (
              <div className="text-black ml-20 font-poppins">
                We currently dont have any user experiences for this situation
              </div>
            )
          )}

          {/* Feedback Button */}
          <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
            <FeedbackForm userId={user?._id} />
          </div>
        </div>

        <div className="hidden md:flex bg-gray-300 shadow-md opacity-90 w-1/6 min-h-screen p-4 rounded-lg flex-col mt-1 mr-1 mb-1">
          {searchSubmitted && manualSearch && !loading && (
            <div className="mb-4">
              {/*<p className="text-white font-poppins mb-2 ml-8">
                For better results click on:
              </p>*/
              /*<button
                className="p-2 bg-slate-900 ml-16 text-white font-poppins rounded-md justify-center"
                onClick={handleRewritePromptClick}
              >
                Rewrite prompt
              </button>*/}
            </div>
          )}

          {/* Show suggestions if available */}
          {showRewritePrompt && threeSuggestions.length > 0 && (
            <div>
              {threeSuggestions.map((suggestion, index) => (
                <button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="p-2 bg-slate-900 text-white font-poppins rounded-md mb-2 w-full"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold font-poppins text-black mb-4">
              Are you sure?
            </h2>
            <p className="mb-6">Do you want to log out?</p>
            <div className="flex justify-around">
              <button
                onClick={() => setShowLogoutPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                No
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-full"
              >
                Yes, Log Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Popup */}
      {showResetPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold text-black mb-4">
              Reset Password
            </h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Current Password
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => setShowResetPasswordPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleResetPassword}
                className="bg-blue-500 text-black px-4 py-2 rounded-full"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
