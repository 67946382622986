import React, { useState, useEffect } from "react";

export default function AlphaRelevance() {
  const [relevanceData, setRelevanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch the relevance data from the backend
  const fetchRelevanceData = async () => {
    try {
      const response = await fetch(
        "https://mindlr.ai/api/get-alpha-relevance",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setRelevanceData(data);
      } else {
        throw new Error("Failed to fetch relevance data");
      }
    } catch (error) {
      console.error("Error fetching relevance data:", error);
      setError("Failed to fetch relevance data. Please try again later.");
    } finally {
      setLoading(false); // Ensure loading stops
    }
  };

  // Fetch relevance data on component mount
  useEffect(() => {
    fetchRelevanceData();
  }, []);

  // Show loading indicator while fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle errors during fetching
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-6 text-black">
        Alpha Relevance Data
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {relevanceData.map((item) => (
          <div
            key={item._id}
            className="bg-white p-6 rounded-lg shadow-md w-80 text-center"
          >
            <h2 className="text-black text-2xl font-semibold mb-2">
              Query: {item.query}
            </h2>
            <p className="text-blue-500 hover:underline break-all">
              Video URL:{" "}
              <a href={item.videoUrl} target="_blank" rel="noopener noreferrer">
                {item.videoUrl}
              </a>
            </p>
            <p className="text-black text-lg font-bold">
              Response: {item.response}
            </p>
            <p className="text-black"></p>
            <p className="text-black">
              Feedback Given At:{" "}
              {new Date(item.feedbackGivenAt).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
